<template>
  <layout-stepper>
    <template #body>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">What type of work do you do?</h1>
            <p>Your listing will appear under one of these categories.</p>
          </b-col>
          <b-col 
            class="col-12 col-md-6 mt-1" 
            v-for="(i, index) in $t('creator.type_of_work')" 
            :key="index"
            ref="card_radio_button"
            @click="getActive(i, index)"
          > 
            <card-radio-button :text="i.text"></card-radio-button>
          </b-col>
          <b-col class="col-12 d-flex justify-content-center">
            <b-button variant="primary" class="my-2" :disabled="selected === null" @click="save()">Next step</b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/creator/layoutStepper.vue';
import cardRadioButton from '@/views/components/creator/cardRadioButton.vue';
import creator_services from '@/services/creator';

export default {
  name: 'category',
  components: {
    BRow,
    BCol,
    BButton,
    layoutStepper,
    cardRadioButton,
  },
  data() {
    return {
      selected: null,
      index_actived: 0,
      steps: null,
      proposal: {}
    }
  },
  created() {
    setTimeout(() => {
      this.getData();
    }, 200);
  },
  methods: {
    getActive(value, index) {
      this.clearActive();
      this.$refs.card_radio_button[index].classList.add('active-class-button');
      this.selected = value;
      this.index_actived = index;
    },
    clearActive() {
      for (let index = 0; index < this.$t('creator.type_of_work').length; index++) {
        this.$refs.card_radio_button[index].classList.remove('active-class-button')
      }
    },
    save() {
      if (this.$route.params.slug !== undefined) {
        this.$swal({
          title: 'Changing your Category will reset your Listing, please confirm.',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Accept',
          cancelButtonText: 'Cancel',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.steps = {work_type: this.selected, index_actived: [this.index_actived]}
            this.steps.uuid = this.proposal.uuid;
            this.steps.slug = this.proposal.slug;
            localStorage.setItem('steps', JSON.stringify(this.steps));
            this.$router.push({name: 'step_subcategory'});
          }
        })
      } else {
        if (this.steps === null) {
          this.steps = {work_type: this.selected, index_actived: [this.index_actived]}
        } else {
          this.steps.work_type = this.selected;
          this.steps.index_actived[0] = this.index_actived;
        }
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({name: 'step_subcategory'});
      }
    },
    getData() {
      if (localStorage.getItem('steps')) {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.getActive(this.steps.work_type, this.steps.index_actived[0])
      } else if (this.$route.params.slug !== undefined) {
        creator_services.detailProposal(this.$route.params.slug).then((response) => {
          this.proposal = response;
          let index_work = 0;
          const work_type = this.$t('creator.type_of_work').find((i, index) => {
            index_work = index;
            return i.value === response.work_type;
          });
          this.getActive(work_type, index_work);
        })
      }
    },
  },
}
</script>
<style>
.active-class-button > .card-radion-button-steps {
  background-color: #7267f005 !important;
  border: 0.0625rem solid #7267f053; 
}
.active-class-button > .card-radion-button-steps > .background-left-proposal {
  background-color: #7367f0 !important;
}
</style>